import React from "react";
import { Link } from "gatsby";
import dateIcon from "../../../../images/dateIcon.svg";
import durationIcon from "../../../../images/durationIcon.svg";
import ShareButtons from "../../../share/share.component";
import Moment from "react-moment";
import Img from "gatsby-image";
import backgroundImage from "../../../../images/blogHeroBackground.svg";

const isBrowser = typeof window !== `undefined`;
function Heading({ data }) {
  const title = data.heading;
  const url = isBrowser && window.location.href;
  const twitterHandle = "_MsLinda";

  return (
    <div className="blogHeroSection">
      <img src={backgroundImage} width="100%" className="heroBackground" alt="heroBackground"/>
      <div className="mobileBackground" />
      <div className="blogHeroContainer">
        <h4 className="heroTitle">{data.title}</h4>
        <h1 className="heroHeading">{data.heading1}</h1>
        <div className="categoryBtn">
          {data.industryList && data.industryList.value && (
            <Link href="#">{data.industryList.value}</Link>
          )}
          {data.technologyList && data.technologyList.value && (
            <Link href="#">{data.technologyList.value}</Link>
          )}
          {data.serviceList && data.serviceList.value && (
            <Link href="#">{data.serviceList.value}</Link>
          )}
        </div>
        <div className="blogInfoContainer">
          <div className="blogInfo">
            <div className="blogDate">
              <img src={dateIcon} alt="dateIcon" />
              <Moment format="MMMM Do YYYY">{data.date}</Moment>
            </div>
            <div className="divider" />
            <div className="blogDuration">
              <img src={durationIcon} alt="durationIcon" />
              <div>{data.time}</div>
            </div>
          </div>
          <ShareButtons title={title} url={url} twitterHandle={twitterHandle} />
        </div>
      </div>
    </div>
  );
}

export default Heading;
