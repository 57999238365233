import React, { createElement } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../../styles/blogContentImage.css";

import savrImg from "../../../../images/savr.png";
import defaultImg from "../../../../images/opsio_contact.webp";
import { AmpXImage } from "../../../customs/ampImage";
import { BlogList } from "./BlogList";
const isBrowser = typeof window !== `undefined`;

const convertMarkdownLinksToReactElements = (inputString) => {
  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
  const parts = inputString.split(regex);
  const result = [];

  for (let i = 0; i < parts.length; i++) {
    if (
      parts[i + 1]?.startsWith("http://") ||
      parts[i + 1]?.startsWith("https://")
    ) {
      result.push(createElement("a", { href: parts[i + 1] }, parts[i]));
      i++;
    } else {
      result.push(parts[i]);
    }
  }

  return result;
};

const BlogContent = ({
  data,
  content,
  isAMP,
  recentBlogs,
  popularBlogs,
  listTitles,
}) => {
  const queryData = useStaticQuery(graphql`
    query {
      NoImage: file(relativePath: { eq: "no-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const NoImage = queryData.NoImage;

  return (
    <div className="detailsContainer">
      <div className="contentContainer">
        {data.blogimg && data?.blogimg[0]?.url ? (
          <AmpXImage
            isAMP={isAMP}
            src={data?.blogimg[0]?.url || defaultImg}
            alt={data?.title || "default"}
            width="100%"
            height={isAMP ? "200" : "auto"}
            className="featured-image"
          />
        ) : (
          <Img
            fluid={NoImage?.childImageSharp?.fluid}
            alt={data?.title}
            className="empty-image"
          />
        )}
        <div className="subContainer">
          <div className="blogTextContainer">
            <ReactMarkdown
              children={data.detailDescription.data.detailDescription}
              rehypePlugins={[rehypeRaw]}
              components={{
                img({ ...props }) {
                  return (
                    <>
                      <div className="blogImage">
                        <AmpXImage
                          isAMP={isAMP}
                          {...props}
                          width="300"
                          height="200"
                        />
                      </div>
                      <div className="banner">
                        <AmpXImage
                          isAMP={isAMP}
                          src={savrImg}
                          alt="savr"
                          width="300"
                          height="200"
                        />
                        <p>{content.blogImgDescription}</p>
                        <Link href="#" className="elevateBtn">
                          {content.elevateBtn}
                        </Link>
                      </div>
                    </>
                  );
                },
                p({ children }) {
                  if (!!children?.length) {
                    children.forEach((child, index) => {
                      if (typeof child === "string") {
                        children[index] =
                          convertMarkdownLinksToReactElements(child);
                      }
                    });
                  }
                  return createElement("p", {}, children || []);
                },
                li({ children }) {
                  if (!!children?.length) {
                    children.forEach((child, index) => {
                      if (typeof child === "string") {
                        children[index] =
                          convertMarkdownLinksToReactElements(child);
                      }
                    });
                  }
                  return createElement("li", {}, children || []);
                },
              }}
            />
          </div>
          <BlogList
            recentBlogs={recentBlogs}
            popularBlogs={popularBlogs}
            listTitles={listTitles}
          />
        </div>
      </div>
    </div>
  );
};

export default BlogContent;
